@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .masonry-3-col {
      column-count: 3;
      column-gap: 1em;
    }
    .masonry-2-col {
      column-count: 2;
      column-gap: 1em;
    }
    .masonry-1-col {
      column-count: 1;
      column-gap: 1em;
    }
    .break-inside {
      break-inside: avoid;
    }
  }
}

@media (min-width: 1024px) {
  .responsive-masonry {
    column-count: 1 !important;
    column-gap: 1em !important;
  }
}

@media (min-width: 1200px) {
  .responsive-masonry {
    column-count: 2 !important;
    column-gap: 1em !important;
  }
}
