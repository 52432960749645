@tailwind base;
@tailwind components;
@tailwind utilities;

/* Default Font Family */
@font-face {
  font-family: 'Inter-Bold';
  src: url(../assets/fonts/inter/Inter-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Light';
  src: url(../assets/fonts/inter/Inter-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Medium';
  src: url(../assets/fonts/inter/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Regular';
  src: url(../assets/fonts/inter/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Semibold';
  src: url(../assets/fonts/inter/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Sora-Bold';
  src: url(../assets/fonts/sora/Sora-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Sora-Light';
  src: url(../assets/fonts/sora/Sora-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Sora-Medium';
  src: url(../assets/fonts/sora/Sora-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Sora-Regular';
  src: url(../assets/fonts/sora/Sora-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Sora-Semibold';
  src: url(../assets/fonts/sora/Sora-SemiBold.ttf) format('truetype');
}

* {
  transition: all 0.3s;
}

@layer base {
  html {
    font-family: Inter-Regular, sans-serif;
    color: #1d1d1f;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.max {
  max-width: 1300px;
}

/* Canvas Component */
.backdrop {
  background: rgba(0, 0, 0, 0.65);
}

.filter-white {
  filter: invert(79%) sepia(100%) saturate(0%) hue-rotate(177deg)
    brightness(113%) contrast(101%);
}

.filter-gray {
  filter: invert(68%) sepia(9%) saturate(302%) hue-rotate(174deg)
    brightness(88%) contrast(91%);
}

/* Modal Animation */

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.select {
  height: 48px;
}

.break {
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.blog-content img {
  display: none !important;
}

.main-blog p {
  @apply font-interRegular text-sm md:text-base;
}

.main-blog a {
  @apply font-interBold text-sm text-primary-main md:text-base;
}

.main-blog ul {
  @apply ml-5 list-disc md:ml-10;
}

.main-blog ol {
  @apply ml-5 list-decimal md:ml-10;
}

.date-icon {
  @apply absolute top-[52%] right-[0%] z-10 flex h-8 w-8 -translate-x-[50%] -translate-y-[50%] cursor-pointer items-center justify-center rounded-full bg-gradient text-white sm:right-[2%] lg:top-[55%] lg:right-[3%];
}

.input[type='date']::-webkit-calendar-picker-indicator,
.input[type='datetime-local']::-webkit-calendar-picker-indicator {
  @apply absolute top-[52%] right-[0%] z-20 h-7 w-7 -translate-x-[50%] -translate-y-[50%] cursor-pointer opacity-0 sm:right-[2%] lg:top-[55%] lg:right-[3%];
}

/* Removes the outline color on focus that Flowbite adds by default */
[multiple]:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='email']:focus,
[type='month']:focus,
[type='number']:focus,
[type='password']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='text']:focus,
[type='time']:focus,
[type='url']:focus,
[type='week']:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
}
